<template>
    <div class="staff-box">
        <el-form v-loading="loading" ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="客户名称" required prop="client">
                <el-autocomplete
                class="inline-input"
                v-model="form.client"
                :fetch-suggestions="getClientList"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item label="备注" prop="remark" required>
                <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item :label="this.CONST.ADD_CERT_IMG">
               <!-- upload -->
                <el-upload
                    v-loading="loading"
                    class="avatar-uploader"
                    :on-error="uploadFail"
                    :before-upload="change"
                    :on-success="uploadSuccess"
                    :data="jsonData"
                    :action="actionUrl"
                    :show-file-list="false">
                    <img v-if="form.src" :src="form.src" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
               <!-- upload -->
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">立即创建</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {formatTime} from '@/utils/util.js'
export default {
    data(){
        return{
            user:{},
            loading:false,
            jsonData:{
                method:'uploadImg'
            },
            actionUrl:this.API.api,
            form:{
                client:''
            },
            rules:{
                client: [
                    { required: true, message: '请输入客户名称', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注内容', trigger: 'blur' },
                    { min: 2, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                ]
            }
        }
    },
    created(){
        this.user = JSON.parse(sessionStorage.getItem('user'))
        this.actionUrl=this.actionUrl+'?token='+this.user.token
    },
    methods:{
        onSubmit(){
            console.log(this.form)
            this.$refs['form'].validate((valid) => {
                let params = this.form
                params.method = "addWorkOrder"
                if(valid){
                    this.loading = true
                    if(this.form.src){
                        params.images=JSON.stringify([this.form.src])
                    }
                        params.nickName = this.user.username
                        params.time = formatTime(new Date())
                    this.$ajax.post(this.API.api,params).then(({msg})=>{
                        //console.log(res)
                        this.$message({
                            message: msg||this.CONST.SUC_ADD,
                            type:'success'
                        })
                        this.$emit("addSuc")
                    }).catch(err=>{
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
                }
            })

        },cancel(){
            this.$emit("cancel")
        },
        change(){
            this.loading=true
        },
        uploadFail(err){
            this.loading = false
            this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
            });
        },
        uploadSuccess(response){
            console.log(response)
            this.loading = false
            if(response.status==0){
            //suc
            this.form.src = this.API.apiUrl+"admin/"+response.data 
            //console.log(this.form)
            }else{
            this.$message({
                message:response.msg,
                type: 'warning'
            });
            }
        },
        //搜索客户
        getClientList(queryString, cb){
            let that=this
                let params = {
                    method:'getClientList',
                    page:1,
                    pageSize:5,
                    client:this.form.client
                }
                //console.log(this.form)
                that.$ajax.post(that.API.api,params).then(res=>{
                    //todo
                    //that.loading = false
                    let list = [] 
                    if(res.data.list){
                        res.data.list.forEach(function(item){
                            list.push({
                                value:item.shortname,
                                cid:item.cid
                            })
                        })
                    }
                    that.clientList = list 
                    that.querySearch(queryString, cb)
                }).catch(err=>{
                    //err
                    console.log(err)
                    that.$message({
                        message: err.msg?err.msg:that.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
                })
        },
        //选择客户
        handleSelect(e){
            console.log(e)
            if(e.cid){
                this.form.cid = e.cid
            }else{
                this.$message({
                    message: this.CONST.CLIENT_NOT_EXIT,
                    type: 'warning'
                });
            }
        },
        querySearch(queryString, cb) {
            var results = queryString ? this.clientList.filter(this.createFilter(queryString)) : this.clientList;
            // 调用 callback 返回建议列表的数据
            //console.log(results)
            cb(results);
           
        },
        createFilter(queryString) {
            return (clientList) => {
            //console.log(clientList,queryString)
            //console.log(clientList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            return (clientList.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1);
            };
        }
    }
}
</script>
<style lang="less">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>