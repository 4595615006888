<template>
    <div class="order-box">
        <div class="client-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户工单</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="editRule">
                    <el-button @click="addWorkOrder" type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item label="客户">
                        <el-autocomplete
                        class="inline-input"
                        v-model="form.client"
                        :fetch-suggestions="searchClient"
                        placeholder="搜索客户"
                        :trigger-on-focus="false"
                        @select="selectClient"
                        ></el-autocomplete>
                </el-form-item>
                <el-form-item label="所在区域">
                    <el-select v-model="form.region" placeholder="所在区域">
                    <el-option v-for="item in parameters.regions" :key="item.pid"
                    :label="item.desc" :value="item.desc"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item >
                <div class="time-box">
                   <el-date-picker
                        v-model="form.date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list -->
        <!-- list-client -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="dataList"
            style="width: 100%">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                    prop="client"
                    width="150"
                    label="客户简称">
                </el-table-column>
                 <el-table-column
                    prop="nickName"
                    width="150"
                    label="预约人">
                </el-table-column>
                <el-table-column
                    prop="region"
                    width="120"
                    label="区域">
                </el-table-column>
                <el-table-column
                    prop="time"
                    width="120"
                    label="时间">
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注">
                </el-table-column>
                <el-table-column
                    prop="images"
                    width="120"
                    label="图片">
                    <template slot-scope="scope">
                        <el-image
                        v-if="scope.row.images.length>=1"
                        style="width: 100px; height: 100px"
                        :src="scope.row.images[0]"
                        :preview-src-list="scope.row.images"
                        fit="cover"></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    width="150"
                    label="状态">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <el-button :disabled="!editRule" size="mini" plain :type="scope.row.status|matchStatus">
                               {{scope.row.status|matchAppoint}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in [0,1,2,4]" :key="item" @click.native="changeSatus(scope.row.oid,item)">
                                {{item|matchAppoint}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="editRule"
                    width="100"
                    fixed="right"
                    label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.oid)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- list -->
        </div>
        <!-- 添加工单 -->
         <el-dialog title="添加工单" 
        :visible.sync="drawerWorkOrder">
            <AddWork @addSuc="addSuc" @cancel="cancel"/>
        </el-dialog>
    </div>
</template>
<script>
import AddWork from '@/components/AddWorkOrder'
import {formatTime,checkRules} from '@/utils/util.js'
export default {
    components:{
        AddWork
    },
    data(){
        return{
            editRule:checkRules(15),//修改权限
            loading:false,
            dataList:[],
            drawerWorkOrder:false,
            clients:[{
                value:'选择'
            }],//选择提示客户列表
            form:{
                client:'',
                region:'',
                date:[]
            },//客户的区域选项
            parameters:{
                type:[],
                regions:[]
            }
        }
    },
    created(){
        this.getParameters()
        this.form.date=[
            new Date(( ( new Date().getTime()/1000 ) - ( 10 * 86400 ) ) * 1000),
            new Date(( ( new Date().getTime()/1000 ) + ( 10 * 86400 ) ) * 1000)
        ]
        //初始化
        this.search()
    },
    methods:{
        //取消添加
        cancel(){
            this.drawerWorkOrder = false
        },
        //删除工单
        handleDelete(e){
            console.log(e)
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    //删除
                    let params = {
                        method:'delWorkOrder',
                        oid:e
                    }
                    this.loading = true
                    this.$ajax.post(this.API.api,params).then(res=>{
                        this.loading = false
                        this.$message({
                            message:res.msg||this.CONST.DEL_SUC,
                            type:'success'
                        })
                        //更新
                        this.search()
                    }).catch(err=>{
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
                }).catch(()=>{
                    this.$message({
                        message: this.CONST.DEL_CANCEL,
                        type: 'info'
                    });
                })
        },
        //添加成功，隐藏
        addSuc(){
            this.drawerWorkOrder = false
            this.search()
        },
        //改变状态,已完成
        changeSatus(oid,item){
            console.log(oid,item)
            let params = {
                method:'changeWorkOrderStatus',
                oid:oid,
                status:item
             }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                this.$message({
                        message:res.msg||this.CONST.EDIT_SUC,
                        type:'success'
                 })
                 this.search()
            }).catch(err=>{
                console.log(err.data)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        },
        //搜索
        search(){
            this.getWorkOrder()
        },
        //弹出搜索客户
        searchClient(queryString, cb){
            var clients = this.clients;
            var results = queryString ? clients.filter(this.createFilter(queryString)) : clients;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (clients) => {
            return (clients.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        //选择客户
        selectClient(){

        },
        //添加工单
        addWorkOrder(){
            this.drawerWorkOrder = true
            this.search()
        },
        //获取工单列表
        getWorkOrder(){
            let params = this.form
            params.method = 'getWorkOrder'
            params.start = formatTime(this.form.date[0]).split(" ")[0]
            params.end = formatTime(this.form.date[1]).split(" ")[0]
            if(params.region!=''){
                //如果选择了区域则清空客户名
                params.client = ''
                this.$message({
                    message: this.CONST.ONLY_REGION,
                    type: 'warning'
                });
            }
            //console.log(params)
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false
                console.log(data)
                if(data){
                    data.forEach(item=>{
                        if(item.images!=''){
                            try {
                                item.images = JSON.parse(item.images)
                            } catch (error) {
                                item.images=[]
                                console.log(error)
                            }
                        }
                    })
                    this.dataList = data
                }
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        },
        //获取客户类型参数
        getParameters(){
            let params = {
                method:"getParameters"
            }
            let that = this
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                res.data.forEach(function(item){
                    if(item.pa_type==1){
                        that.parameters.type.push(item)
                    }
                    else if(item.pa_type==2){
                        that.parameters.regions.push(item)
                    }
                })
            }).catch(err=>{
                console.log(err)
                //err
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        }
    }
}
</script>